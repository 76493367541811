import Image from 'next/image';
import styled from 'styled-components';
import { COLORS } from 'config/theme';

type SocialIconLinkProps = {
  src: string;
  href: string;
  alt: string;
  width: number;
  height: number;
  isTarget?: boolean;
  background?: string;
};

const StyledA = styled.a<Pick<SocialIconLinkProps, 'background' | 'width'>>`
  background: ${({ background }) => background || COLORS.V2.SECONDARY};
  height: ${({ width }) => `${width * 2.5}px`};
  width: ${({ width }) => `${width * 2.5}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  @media ${({ theme }) => theme.DEVICE.MD} {
    height: ${({ width }) => `${width * 3}px`};
    width: ${({ width }) => `${width * 3}px`};
  }
`;

function SocialIconLink({ src, href, alt, width, height, isTarget, background }: SocialIconLinkProps): JSX.Element {
  return (
    <StyledA width={width} href={href} target={isTarget ? '_blank' : ''} rel="noreferrer" background={background}>
      <Image src={src} alt={alt} width={width} height={height} />
    </StyledA>
  );
}

export default SocialIconLink;
