import styled from 'styled-components';
import { secondaryBlueTransition } from 'shared/components/mixins';

type ArrowBtnProps = {
  align?: string;
};

const ArrowBtn = styled.button`
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 20px;
  position: absolute !important;
  z-index: 1;
  ${secondaryBlueTransition};

  &::before {
    border: solid ${({ theme }) => theme.COLORS.PRIMARY};
    border-width: 0 3px 3px 0;
    content: '';
    display: inline-block;
    opacity: 0.8;
    padding: 6px;
    position: absolute;
    top: 50%;
  }
`;

export const ArrowBtnRight = styled(ArrowBtn)`
  right: 12px;
  transform: translateX(1.8rem);

  &::before {
    right: 50%;
    transform: translate(35%, -50%) rotate(-45deg);
  }
`;

export const ArrowBtnLeft = styled(ArrowBtn)`
  left: 12px;
  transform: translateX(-1.8rem);

  &::before {
    left: 50%;
    transform: translate(-35%, -50%) rotate(135deg);
  }
`;

const ArrowBtnV2 = styled.button`
  height: 50px;
  width: 50px;
  cursor: pointer;
  position: absolute !important;
  bottom: 0;
  border: none;
  padding: 0;
  background: transparent;
  z-index: 100;
`;

export const ArrowBtnLeftV2 = styled(ArrowBtnV2)<ArrowBtnProps>`
  left: ${({ align }) => (align === 'right' ? 'calc(100% - 110px)' : 'calc(50% - 55px)')};

  ${({ theme }) => theme.MEDIA.XL} {
    left: calc(45% - 55px);
  }

  :before {
    width: 27px;
    height: 11px;
    content: '';
    position: absolute;
    top: 19px;
    left: 9px;
    background-image: url("data:image/svg+xml;utf8,<svg width='27' height='11' viewBox='0 0 27 11' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.57357 5.4099L7.00376 10.7378L7.00376 6.47547L26.1473 6.47548L26.1473 4.34433L7.00376 4.34433L7.00376 0.0820315L0.57357 5.4099Z' fill='%2396B0B8'/></svg>");
  }
  :hover {
    background: ${({ theme }) => theme.COLORS.V2.SECONDARY};

    ::before {
      background-image: url("data:image/svg+xml;utf8,<svg width='27' height='11' viewBox='0 0 27 11' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0.57357 5.4099L7.00376 10.7378L7.00376 6.47547L26.1473 6.47548L26.1473 4.34433L7.00376 4.34433L7.00376 0.0820315L0.57357 5.4099Z' fill='white'/></svg>");
    }
  }
`;

export const ArrowBtnRightV2 = styled(ArrowBtnV2)<ArrowBtnProps>`
  right: ${({ align }) => (align === 'right' ? 0 : 'calc(50% - 55px)')};
  background: ${({ theme }) => theme.COLORS.V2.SECONDARY};

  ${({ theme }) => theme.MEDIA.XL} {
    right: calc(55% - 55px);
  }

  :before {
    width: 27px;
    height: 11px;
    content: '';
    position: absolute;
    top: 19px;
    right: 9px;
    background-image: url("data:image/svg+xml;utf8, <svg width='27' height='12' viewBox='0 0 27 12' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M26.0319 6.19557L19.6017 0.8677L19.6017 5.12999L0.45813 5.12999L0.45813 7.26114L19.6017 7.26114L19.6017 11.5234L26.0319 6.19557Z' fill='white'/></svg>");
  }
`;

const ArrowBtnV3 = styled.button`
  background-color: #fc8000;
  border: none;
  bottom: 0;
  cursor: pointer;
  height: 15rem;
  padding: 0;
  position: absolute !important;
  width: 15rem;
  z-index: 100;

  :before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg width='51' height='51' viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.0366 25.668L36.172 39.2574C36.5612 39.6307 36.7753 40.1298 36.7753 40.662C36.7753 41.1944 36.5612 41.6932 36.172 42.0671L34.9334 43.2572C34.5448 43.6317 34.0253 43.8378 33.4717 43.8378C32.9182 43.8378 32.3993 43.6317 32.0104 43.2572L15.1799 27.0778C14.7894 26.7028 14.5756 26.2016 14.5772 25.6689C14.5756 25.1338 14.7891 24.6332 15.1799 24.2578L31.9947 8.09411C32.3836 7.71964 32.9025 7.5135 33.4564 7.5135C34.0099 7.5135 34.5288 7.71964 34.918 8.09411L36.1563 9.28424C36.9621 10.0589 36.9621 11.3199 36.1563 12.0942L22.0366 25.668Z' fill='white'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
  }

  :hover {
    background-color: ${({ theme }) => theme.COLORS.V2.SECONDARY};
  }
`;

export const ArrowBtnLeftV3 = styled(ArrowBtnV3)`
  left: 0;

  :before {
    bottom: 0;
    left: 0;
  }
`;

export const ArrowBtnRightV3 = styled(ArrowBtnV3)`
  right: 0;

  :before {
    transform: rotate(180deg);
    bottom: 0;
    right: 0;
  }
`;
