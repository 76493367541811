import { useState, useRef, useCallback } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'shared/hooks/useTranslation';
import styled, { css } from 'styled-components';
import ROUTES from 'config/routes';
import { getLanguageHref } from 'shared/utils/subdomains';
import useOutsideClick from 'shared/hooks/useOutsideClick';
import useLanguageSubdomains from 'shared/hooks/useLanguageSubdomains';
import useSubdomain from 'shared/hooks/useSubdomain';
import AngleDownIcon from 'shared/components/AngleDownIcon';

type WrapperProps = {
  colorInverted?: boolean;
};

const Wrapper = styled.div<WrapperProps>`
  align-self: center;
  cursor: pointer;
  position: relative;
  margin-left: 1.8rem;
  max-width: 18rem;
  color: ${({ colorInverted, theme }) => (colorInverted ? theme.COLORS.ASH : 'black')};
`;

type ActiveLanguageProps = {
  mobile?: boolean;
};

const ActiveLanguage = styled.div<ActiveLanguageProps>`
  align-items: center;
  color: ${({ mobile }) => (mobile ? 'black' : '#FFFFFF')};
  display: flex;
  padding: ${({ mobile }) => (mobile ? '2rem 1rem' : '1.6rem')};
  font-weight: ${({ mobile }) => (mobile ? '600' : '400')};
  width: max-content;
  justify-content: center;
  ${({ mobile, theme }) =>
    mobile &&
    css`
      color: ${theme.COLORS.ASH};
      font-size: 1.8rem;
    `}

  .current-language {
    margin-right: 2rem;
  }
`;

type IconProps = {
  isRotated: boolean;
};

export const Icon = styled(AngleDownIcon)<IconProps>`
  margin-bottom: 1px;
  transition: transform 0.3s ease;
  ${({ isRotated }) =>
    !isRotated &&
    css`
      transform: rotate(-90deg);
    `}
`;

type ListProps = {
  isVisible: boolean;
  mobile?: boolean;
};

const List = styled.ul<ListProps>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  list-style-type: none;
  max-height: 28rem;
  overflow-y: scroll;
  margin-top: 0.3em;
  background-color: ${({ mobile }) => (mobile ? 'transparent' : '#fff')};
  position: absolute;
  width: fit-content;
  z-index: 10;
  ${({ mobile }) =>
    mobile &&
    css`
      transform: translate(1.4rem, -1.8rem);
      left: 0;
      padding: 1rem 2.6rem 1rem 0;
    `}
  ${({ mobile }) =>
    !mobile &&
    css`
      right: -2rem;
      padding: 1rem 2.6rem;
    `}

  &::-webkit-scrollbar {
    width: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: ${({ theme }) => theme.BORDER_RADIUS};
    background-color: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ mobile }) => (!mobile ? 'black' : '#fff')};
    outline: none;
    border-radius: ${({ theme }) => theme.BORDER_RADIUS};
  }

  ${({ theme }) => theme.MEDIA.XL} {
    max-height: 38rem;
  }

  li {
    background: #ffffff;
    font-size: 1.8rem;
    ${({ mobile, theme }) =>
      mobile &&
      css`
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        color: ${theme.COLORS.ASH};
        margin: 0.4rem 0 0.4rem 0;
        background: transparent;
      `}
    max-height: 80%;
  }
`;

type LanguageLinkProps = {
  mobile: boolean;
};

const LanguageLink = styled.a<LanguageLinkProps>`
  align-items: center;
  background: transparent;
  border: none;
  border-radius: ${({ theme }) => theme.BORDER_RADIUS};
  color: ${({ mobile }) => (!mobile ? 'black' : '#ffffff')};
  cursor: pointer;
  display: flex;
  font-size: 1.8rem;
  padding: 1.8rem 1.4rem;
  text-align: left;
  text-decoration: none;
  transition: 0.3s;
  width: 100%;

  &:hover,
  &:focus {
    opacity: 0.6;
    outline: none;
  }
`;

const CountryFlag = styled.div`
  align-items: center;
  display: flex;
  justify-items: center;
  margin-right: 0.8rem;
  min-width: 3.2rem;
`;

const ActiveCountryFlag = styled(CountryFlag)`
  border-radius: 100%;
  border: 0.4rem solid rgba(0, 0, 0, 0.1);
`;

type LanguageListItemProps = {
  language: string;
  href: string;
  onClick: () => void;
  mobile: boolean;
};

function LanguageListItem({ language, href, onClick, mobile }: LanguageListItemProps): JSX.Element | null {
  if (language === 'ru') return null;
  return (
    <li>
      <LanguageLink onClick={onClick} href={href} mobile={mobile}>
        <CountryFlag>
          <Image
            src={`/img/flags/v2/${language}.svg`}
            alt={`language - ${language}`}
            height={32}
            width={32}
            lazyBoundary="1000px"
          />
        </CountryFlag>
        {language === 'pt' ? 'PT-BR' : language.toUpperCase()}
      </LanguageLink>
    </li>
  );
}

type LanguageSelectorProps = {
  colorInverted?: boolean;
  mobile?: boolean;
};

function LanguageSelector({ colorInverted = false, mobile = false }: LanguageSelectorProps): JSX.Element {
  const { lang: currentLanguage } = useTranslation();
  const router = useRouter();

  const activeMarketRef = useRef<HTMLDivElement>(null);
  const [isListVisible, setListVisible] = useState(false);
  const onOutsideClick = useCallback(() => {
    setListVisible(false);
  }, []);
  const languageSubdomains = useLanguageSubdomains();
  const chooseSubdomain = useSubdomain();

  useOutsideClick(activeMarketRef, onOutsideClick);

  type RedirectProps = {
    language: string;
  };

  function redirect({ language }: RedirectProps) {
    if (router.pathname.startsWith(ROUTES.COMPARISON.COMPARISON)) {
      router.push(ROUTES.HOME);
    } else {
      chooseSubdomain(languageSubdomains.subdomains[language], false);
    }
  }

  function handleClick() {
    setListVisible(!isListVisible);
  }

  return (
    <Wrapper colorInverted={colorInverted}>
      <ActiveLanguage
        mobile={mobile}
        ref={activeMarketRef}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={handleClick}
      >
        <ActiveCountryFlag>
          <Image
            src={`/img/flags/v2/${currentLanguage}.svg`}
            alt={`current language - ${currentLanguage}`}
            height={32}
            width={32}
            lazyBoundary="1000px"
          />
        </ActiveCountryFlag>
        <Icon dark={!mobile} isRotated={isListVisible} />
      </ActiveLanguage>
      <List mobile={mobile} isVisible={isListVisible}>
        {languageSubdomains.languages.map((language) =>
          language !== currentLanguage ? (
            <LanguageListItem
              key={language}
              language={language}
              href={getLanguageHref(
                languageSubdomains.subdomains[language].subdomain,
                router.asPath,
                currentLanguage,
                language,
              )}
              onClick={() => redirect({ language })}
              mobile={mobile}
            />
          ) : null,
        )}
      </List>
    </Wrapper>
  );
}

export default LanguageSelector;
