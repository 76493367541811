import { useMemo } from 'react';
import Cookies from 'js-cookie';

export const LANGUAGE_COOKIE_NAME = 'language';

type CookieType = string | undefined;

function useLanguageCookie(): CookieType {
  return useMemo(() => Cookies.get(LANGUAGE_COOKIE_NAME), []);
}

export default useLanguageCookie;
