import styled from 'styled-components';
import Image from 'next/image';

type StarsProps = {
  marginBottom: string;
};

const Stars = styled.div<StarsProps>`
  display: flex;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  gap: 0.8rem;

  .transparent {
    opacity: 0.2;
    filter: brightness(0.5) sepia(1) hue-rotate(140deg) saturate(6);
  }
`;

type StarsGradeProps = {
  grade: number;
  width?: number;
  height?: number;
  marginBottom?: string;
};

function StarsGrade({ grade, width = 14, height = 17, marginBottom = '0px' }: StarsGradeProps): JSX.Element {
  return (
    <Stars marginBottom={marginBottom}>
      {Array.from({ length: 5 }, (v, k) => (
        <Image
          key={k}
          src="/img/shared/tm-star-v2.svg"
          alt={`star${k + 1}`}
          width={width}
          height={height}
          className={k + 1 <= grade ? undefined : 'transparent'}
          lazyBoundary="1000px"
        />
      ))}
    </Stars>
  );
}

export default StarsGrade;
