type AngleDownIconProps = {
  dark: boolean;
  className?: string;
};

function AngleDownIcon({ dark, className }: AngleDownIconProps): JSX.Element {
  return (
    <svg
      className={className}
      width="17"
      height="10"
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.888619 1.89807C0.887436 1.69335 0.926675 1.49041 1.00409 1.30088C1.0815 1.11136 1.19556 0.938978 1.33973 0.793627C1.48434 0.647827 1.65639 0.532103 1.84594 0.453129C2.0355 0.374156 2.23882 0.333496 2.44418 0.333496C2.64953 0.333496 2.85285 0.374156 3.04241 0.453129C3.23197 0.532103 3.40401 0.647827 3.54862 0.793627L8.6664 5.94252L13.8153 0.995849C14.1067 0.706125 14.501 0.543505 14.912 0.543505C15.3229 0.543505 15.7172 0.706125 16.0086 0.995849C16.1544 1.14046 16.2701 1.3125 16.3491 1.50206C16.4281 1.69162 16.4688 1.89494 16.4688 2.10029C16.4688 2.30565 16.4281 2.50897 16.3491 2.69852C16.2701 2.88808 16.1544 3.06013 16.0086 3.20474L9.7864 9.20918C9.49562 9.4942 9.10468 9.65385 8.69751 9.65385C8.29034 9.65385 7.8994 9.4942 7.60862 9.20918L1.3864 2.98696C1.23561 2.84734 1.11399 2.67922 1.02855 2.49232C0.943113 2.30543 0.895554 2.10345 0.888619 1.89807Z"
        fill={dark ? 'black' : '#ffffff'}
        fillOpacity={dark ? '0.1' : '0.7'}
      />
    </svg>
  );
}

export default AngleDownIcon;
