import { useEffect, useReducer } from 'react';

type MediaState = {
  isMatch: boolean;
  mediaLoaded: boolean;
};

type MediaActionTypes = { type: 'mediaChanged'; payload: boolean };

function mediaReducer(state: MediaState, action: MediaActionTypes): MediaState {
  switch (action.type) {
    case 'mediaChanged':
      return {
        isMatch: action.payload,
        mediaLoaded: true,
      };

    default:
      throw new Error('Unsupported action type in mediaReducer');
  }
}

function useMediaQuery(query: string): MediaState {
  const [state, dispatch] = useReducer(mediaReducer, {
    isMatch: false,
    mediaLoaded: false,
  });

  useEffect(() => {
    let mounted = true;
    const mql = window.matchMedia(query);
    function onChange() {
      if (!mounted) {
        return;
      }
      dispatch({ type: 'mediaChanged', payload: mql.matches });
    }

    mql.addEventListener('change', onChange);
    dispatch({ type: 'mediaChanged', payload: mql.matches });

    return () => {
      mounted = false;
      mql.removeEventListener('change', onChange);
    };
  }, [query]);

  return state;
}

export default useMediaQuery;
