import { useCallback } from 'react';
import Cookies from 'js-cookie';
import { LANGUAGE_COOKIE_NAME } from 'shared/hooks/useLanguageCookie';
import { Subdomain } from 'shared/utils/subdomains';

type ChooseSubdomainType = (subdomain: Subdomain, withRedirect?: boolean) => void;

function useSubdomain(): ChooseSubdomainType {
  return useCallback(({ subdomain, language }: Subdomain, withRedirect = true): void => {
    let suffix = subdomain.substring(subdomain.lastIndexOf('.'));
    if (process.env.NEXT_PUBLIC_APP_ENV === 'dev') {
      suffix = suffix.replace(':3443', '');
      suffix = suffix.replace(':3000', '');
    }
    const domain = `trustmate${suffix}`;
    Cookies.set(LANGUAGE_COOKIE_NAME, language, { domain });
    if (withRedirect) {
      window.location.href = `${window.location.protocol}//${subdomain}`;
    }
  }, []);
}

export default useSubdomain;
