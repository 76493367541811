import { ReactNode } from 'react';
import styled from 'styled-components';

import WEB_ROUTES, { I18N_ROUTES } from 'config/routes';
import Image from 'next/image';
import ImageLink from 'shared/components/ImageLink';
import NextLink from 'shared/components/NextLink';
import SocialIconLink from 'shared/components/SocialIconLinkV2';
import { useDarkMode } from 'shared/hooks/useDarkMode';
import useTranslation from 'shared/hooks/useTranslation';

import { DarkMode } from './types';

const FooterWrapper = styled.footer<DarkMode>`
  background: ${({ theme }) => theme.THEME_MODE?.BACKGROUND};
  padding: 6.4rem 0 6rem 0;
  font-family: ${({ theme }) => theme.FONTS.MAIN};

  a {
    font-family: ${({ theme }) => theme.FONTS.MAIN};
  }
`;

const SocialIconsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 0.8rem;
  left: 0;
  width: 100%;
  justify-content: center;

  ${({ theme }) => theme.MEDIA.SM} {
    left: 2rem;
    width: fit-content;
  }

  ${({ theme }) => theme.MEDIA.SM} {
    left: 4rem;
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.2rem;
`;

const StyledImg = styled.img`
  height: 32px;

  @media (min-width: 576px) {
    height: 54px;
  }
`;

type GridProps = {
  columns: number;
};

const Grid = styled.div<GridProps>`
  position: relative;
  display: grid;
  max-width: 160rem;
  gap: 3rem;
  padding: 0 4rem 20rem 4rem;
  align-items: flex-start;
  justify-content: center;
  color: ${({ theme }) => theme.THEME_MODE?.FOOTER_SECONDARY};
  margin: 0 auto;
  flex-direction: column;

  ${({ theme }) => theme.MEDIA.SM} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.MEDIA.XL} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${({ theme }) => theme.MEDIA.XXL} {
    display: flex;
    padding: 0 4rem;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Text = styled.p`
  margin-bottom: 2.6rem;
`;

const FooterSection = styled.div`
  h3 {
    color: ${({ theme }) => theme.THEME_MODE?.TEXT};
    text-transform: capitalize;
    margin-bottom: 3.2rem;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 2.9rem;
    letter-spacing: 0em;
    text-align: center;

    ${({ theme }) => theme.MEDIA.SM} {
      text-align: left;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li {
      margin: 0 0 2.6rem 0;
    }
  }

  li,
  p {
    font-size: 1.9rem;
    font-weight: 400;
    line-height: 2.3rem;
    letter-spacing: 0rem;
    text-align: center;

    ${({ theme }) => theme.MEDIA.SM} {
      text-align: left;
    }
  }
  a {
    color: inherit;
    position: relative;
    text-decoration: none;

    &:before {
      background: currentColor;
      bottom: -2px;
      content: '';
      height: 1px;
      position: absolute;
      transform: scaleX(0);
      transition: transform 0.3s;
      width: 100%;
    }

    &:hover,
    &:focus {
      &:before {
        transform: scaleX(1);
      }
    }
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  bottom: 10rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.MEDIA.SM} {
    right: 2rem;
    bottom: 0;
    width: fit-content;
    transform: translateX(0);
  }
  ${({ theme }) => theme.MEDIA.MD} {
    right: 4rem;
  }
`;

const FooterLink = styled.a``;

type FooterNextLinkProps = {
  href: string;
  children: ReactNode;
};

function FooterNextLink({ href, children }: FooterNextLinkProps): JSX.Element {
  return (
    <NextLink href={href} passHref>
      {children}
    </NextLink>
  );
}

function Footer(): JSX.Element {
  const { t, lang } = useTranslation('footer');
  const { theme: themeMode } = useDarkMode();

  return (
    <FooterWrapper>
      <Grid columns={5}>
        <div>
          {lang === 'pl' ? (
            <FooterSection>
              <h3>{t('section1.headerComparison')}</h3>
              <ul>
                <li>
                  <FooterNextLink href={WEB_ROUTES.COMPARISON.OPINEO}>TrustMate vs Opineo</FooterNextLink>
                </li>
                <li>
                  <FooterNextLink href={WEB_ROUTES.COMPARISON.TRUSTEDSHOPS}>TrustMate vs Trustedshops</FooterNextLink>
                </li>
                <li>
                  <FooterNextLink href={WEB_ROUTES.COMPARISON.ZAUFANE}>TrustMate vs Zaufane.pl</FooterNextLink>
                </li>
              </ul>
            </FooterSection>
          ) : null}
          <FooterSection>
            <h3>{t('section2.header')}</h3>
            <ul>
              <li>
                <FooterNextLink href={WEB_ROUTES.PRICING}>{t('section2.link1')}</FooterNextLink>
              </li>
            </ul>
            <SocialIconsWrapper>
              <SocialIconLink
                src="/img/social-icons/facebook.svg"
                href="https://www.facebook.com/trustmate.io"
                alt="Facebook logo"
                width={25}
                height={25}
                isTarget
              />
              <SocialIconLink
                src="/img/social-icons/instagram.svg"
                href="https://www.instagram.com/trustmate/"
                alt="Facebook logo"
                width={25}
                height={25}
                isTarget
              />
              <SocialIconLink
                src="/img/social-icons/linked.svg"
                href="https://www.linkedin.com/company/trustmate/mycompany/"
                alt="Linkedin logo"
                width={25}
                height={25}
                isTarget
              />
            </SocialIconsWrapper>
          </FooterSection>
        </div>
        <FooterSection>
          <h3>{t('section4.header')}</h3>
          <ul>
            <li>
              <FooterLink target="_blank" href={WEB_ROUTES.BUSINESS_REGULATIONS}>
                {t('section4.link1')}
              </FooterLink>
            </li>
            <li>
              <FooterLink target="_blank" href={WEB_ROUTES.USER_REGULATIONS}>
                {t('section4.link2')}
              </FooterLink>
            </li>
            <li>
              <FooterLink target="_blank" href={WEB_ROUTES.PRIVACY_POLICY}>
                {t('section4.link3')}
              </FooterLink>
            </li>
          </ul>
        </FooterSection>
        <FooterSection>
          <h3>{t('section1.header')}</h3>
          <ul>
            <li>
              <FooterNextLink href={WEB_ROUTES.CONTACT}>{t('section1.link1')}</FooterNextLink>
            </li>
            {lang === 'pl' ? (
              <li>
                <FooterNextLink href={WEB_ROUTES.SA_INFO}>Informacje dla akcjonariuszy</FooterNextLink>
              </li>
            ) : null}
            {lang === 'pl' ? (
              <li>
                <FooterLink target="_blank" href={WEB_ROUTES.BLOG}>
                  {t('section1.link2')}
                </FooterLink>
              </li>
            ) : null}
            <li>
              <FooterLink target="_blank" href={WEB_ROUTES.PODCAST}>
                Podcast
              </FooterLink>
            </li>
            <li>
              <FooterNextLink href={WEB_ROUTES.REVIEW_COLLECTION}>{t('section1.link8')}</FooterNextLink>
            </li>
            <li>
              <FooterLink target="_blank" href={WEB_ROUTES.REVIEWS}>
                {t('section1.link6')}
              </FooterLink>
            </li>
            <li>
              <FooterNextLink href={I18N_ROUTES[lang].CATEGORY_MAIN}>{t('section1.link3')}</FooterNextLink>
            </li>
            <li>
              <FooterNextLink href={WEB_ROUTES.PARTNERS}>{t('section1.link4')}</FooterNextLink>
            </li>
            <li>
              <FooterNextLink href={WEB_ROUTES.USER_HOME_PAGE}>{t('section1.link5')}</FooterNextLink>
            </li>
            {lang === 'pl' ? (
              <li>
                <FooterNextLink href={WEB_ROUTES.JOBS_BOARD}>{t('section1.link7')}</FooterNextLink>
              </li>
            ) : null}
            <li>
              <FooterNextLink href={WEB_ROUTES.TEAM}>Team</FooterNextLink>
            </li>
          </ul>
        </FooterSection>
        {lang === 'pl' ? (
          <FooterSection>
            <h3>{t('integrations', 'common')}</h3>
            <ul>
              <li>
                <FooterLink href="https://platforms.trustmate.io/pl/prestashop-trustmate">PrestaShop</FooterLink>
              </li>
              <li>
                <FooterLink href="https://platforms.trustmate.io/pl/shopertrustmate">Shoper</FooterLink>
              </li>
              <li>
                <FooterLink href="https://platforms.trustmate.io/pl/woocommerce-trustmate">WooCommerce</FooterLink>
              </li>
              <li>
                <FooterLink href="https://platforms.trustmate.io/pl/opencart-trustmate">OpenCart</FooterLink>
              </li>
              <li>
                <FooterLink href="https://platforms.trustmate.io/pl/homepltrustmate">Home</FooterLink>
              </li>
              <li>
                <FooterLink href="https://platforms.trustmate.io/pl/atomstore-trustmate-1-1">AtomStore</FooterLink>
              </li>
            </ul>
          </FooterSection>
        ) : null}
        <FooterSection>
          <h3>{t('section3.header')}</h3>
          <Text>TrustMate S.A.</Text>
          <Text>Bartoszowicka 3, 51-641</Text>
          <Text>
            {t('section3.city')}, {t('section3.country')}
          </Text>
        </FooterSection>
        <ImageWrapper>
          <ImageLink
            width={308}
            height={64}
            src={`/img/logos/logo-trustmate-2022-${themeMode}.svg`}
            alt="TrustMate Logo"
            href={WEB_ROUTES.HOME}
          />
        </ImageWrapper>
      </Grid>
      {lang === 'en' || lang === 'fr' ? (
        <ImgWrapper>
          <StyledImg src="/img/shared/footereu.svg" alt="Eu logos" />
        </ImgWrapper>
      ) : null}
      {lang === 'pl' ? (
        <ImgWrapper>
          <Image
            style={{ maxWidth: '100vw', height: 'auto' }}
            src="/img/shared/footereu.jpg"
            alt="logo ue"
            width={508}
            height={70}
            quality={100}
          />
        </ImgWrapper>
      ) : null}
    </FooterWrapper>
  );
}

export default Footer;
