/* eslint-disable import/prefer-default-export */

export function truncate(str: string, maxLength: number): string {
  if (str && maxLength > 0 && str.length > maxLength) {
    return `${str.substring(0, maxLength + 1)}...`;
  }
  return str;
}

export function capitalizeFirstLetter(str: string): string {
  if (str) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  }
  return str;
}
