import { useMemo } from 'react';
import i18nConfig from 'i18n';
import { getNormalizedSubdomains, Subdomain } from 'shared/utils/subdomains';

type NormalizedSubdomains = {
  languages: string[];
  subdomains: {
    [key: string]: Subdomain;
  };
};

function useLanguageSubdomains(): NormalizedSubdomains {
  return useMemo(
    () => ({
      languages: [...(i18nConfig.locales as string[])],
      subdomains: getNormalizedSubdomains(),
    }),
    [],
  );
}

export default useLanguageSubdomains;
