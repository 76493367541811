import i18nConfig from 'i18n';

export type Subdomain = {
  language: string;
  subdomain: string;
};

type LanguageSubdomains = {
  [key: string]: Subdomain;
};

export function getNormalizedSubdomains(): LanguageSubdomains {
  return i18nConfig.domains.reduce(
    (acc, { domain, defaultLocale }) => ({
      ...acc,
      [defaultLocale]: {
        language: defaultLocale,
        subdomain: process.env.NEXT_PUBLIC_APP_ENV === 'dev' ? `${domain}:3443` : domain,
      },
    }),
    {},
  );
}

interface TranslatedRoutes {
  [key: string]: string[];
}

const translatedRoutes: TranslatedRoutes = {
  de: ['bewertungen/', 'kategorien/online-shops', 'kategorien/unternehmen', 'kategorien'],
  pl: ['opinie/', 'kategorie/sklepy', 'kategorie/firmy', 'kategorie'],
  bg: ['otzivi/', 'kategorii-bg/magaziny-bg', 'kategorii-bg/kategorii-bg', 'kategorii-bg'],
  cs: ['hodnoceni/', 'kategorie-cs/obchody', 'kategorie-cs/sluzby', 'kategorie-cs'],
  el: ['vathmologies/', 'katigories/katastimata', 'katigories/ypiresies', 'katigories'],
  en: ['reviews/', 'categories/shops', 'categories/services', 'categories'],
  es: ['opiniones/', 'categorias-es/tienda', 'categorias-es/servicios', 'categorias-es'],
  et: ['arvustused/', 'kategooriad/poed', 'kategooriad/teenused', 'kategooriad'],
  fr: ['avis/', 'categories/magasins', 'categories/prestations-de-service', 'categories'],
  hr: ['recenzije/', 'kategorije/trgovine', 'kategorije/usluge', 'kategorije'],
  hu: ['ertekelesek/', 'kategoriak/uzletek', 'kategoriak/szolgaltatasok', 'kategoriak'],
  it: ['recensioni/', 'categorie/negozi', 'categorie/servizi', 'categorie'],
  lt: ['atsiliepimai/', 'kategorijos/parduotuves', 'kategorijos/paslaugos', 'kategorijos'],
  lv: ['atsauksmes/', 'kategorijas/veikali', 'kategorijas/pakalpojumus', 'kategorijas'],
  nl: ['reviews-nl/', 'categorieen/winkels', 'categorieen/bedrijven', 'categorieen'],
  no: ['anmeldelser/', 'kategorier-no/butikker', 'butikker/tjenester', 'kategorier-no'],
  pt: ['opinioes/', 'categorias-pt/lojas', 'categorias-pt/empresas', 'categorias-pt'],
  ro: ['recenzie/', 'categorii/magazine', 'categorii/companii', 'categorii'],
  ru: ['otzyvy/', 'kategorii/magaziny', 'kategorii/servisy', 'kategorii'],
  sk: ['hodnoteni/', 'kategorie-sk/obchody', 'kategorie-sk/sluzby', 'kategorie-sk'],
  sl: ['mnenja-in-ocene/', 'kategorije-sl/trgovine-sl', 'kategorije-sl/storitve', 'kategorije-sl'],
  sr: ['recenzije-sr', 'kategorije-sr/prodavnice', 'kategorije-sr/usluge-sr', 'kategorije-sr'],
  sv: ['omdomen/', 'kategorier/onlinebutiker', 'kategorier/tjanster', 'kategorier'],
  uk: ['vidhuky/', 'katehoriyi/mahazyn', 'katehoriyi/kompaniyi', 'katehoriyi'],
  tr: ['yorumlar/', 'kategori̇ler/mağazalar', 'kategori̇ler/hizmetler', 'kategori̇ler'],
  da: ['anmeldelser/', 'kategorier-da/butikker', 'kategorier-da/tjenester', 'kategorier-da'],
  fi: ['arvostelut/', 'kategoriat/kaupat', 'kategoriat/palvelut', 'kategoriat'],
};

export function getLanguageHref(subdomain: string, path: string, currentLanguage?: string, language?: string): string {
  let translatedPath;
  const origin = `https://${subdomain}`;
  const pathWithoutQuery = path.split('?')[0];

  if (language && currentLanguage) {
    translatedRoutes[currentLanguage].find((el, index) => {
      if (pathWithoutQuery.includes(el)) {
        translatedPath = pathWithoutQuery.replace(
          translatedRoutes[currentLanguage][index],
          translatedRoutes[language][index],
        );
        return pathWithoutQuery === '/' ? origin : `${origin}${translatedPath}`;
      }
      return null;
    });
  }

  if (translatedPath) {
    return `${origin}${translatedPath}`;
  }

  return pathWithoutQuery === '/' ? origin : `${origin}${pathWithoutQuery}`;
}
