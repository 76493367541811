import Image from 'next/image';
import styled from 'styled-components';
import useTranslation from 'shared/hooks/useTranslation';
import Carousel from 'react-multi-carousel';
import ToolTip from 'shared/components/Tooltip/Tooltip';
import { useDarkMode } from 'shared/hooks/useDarkMode';
import { truncate } from 'shared/utils/stringFns';
import { I18N_ROUTES } from 'config/routes';
import { getTimeAgo } from 'shared/utils/dates';
import { Review } from 'shared/api/latestReviews';
import { scrollBar } from 'shared/components/mixins';
import NextLink from 'shared/components/NextLink';
import StarsGrade from 'shared/components/StarsGradeV2';
import { ArrowBtnLeftV2, ArrowBtnRightV2 } from 'shared/components/CarouselArrows';

const NAME_MAX_LENGTH = 32;

type ReviewBoxProps = {
  review: Review;
};

function ReviewBox({ review }: ReviewBoxProps): JSX.Element {
  const { lang } = useTranslation('common');

  const { authorName, grade, createdAt, body, accountSlug, accountName } = review;

  return (
    <Wrapper>
      <div>
        <ToolTip title={authorName} position="top" width="220px">
          <AuthorName>{truncate(authorName, NAME_MAX_LENGTH)}</AuthorName>
        </ToolTip>
        <StarsGrade grade={grade} />
        <Time>{getTimeAgo(new Date(createdAt), lang)}</Time>
        <Text>{body}</Text>
      </div>
      <CompanyName>
        <NextLink href={I18N_ROUTES[lang].COMPANY_PROFILE.replace(':slug', accountSlug)}>
          <ToolTip title={accountName} position="top" width="220px">
            <a>{truncate(accountName, NAME_MAX_LENGTH)}</a>
          </ToolTip>
        </NextLink>
      </CompanyName>
    </Wrapper>
  );
}

export type LatestReviewsProps = {
  reviews: Review[];
};

const responsive = {
  desktop: {
    breakpoint: { min: 1569, max: 3000 },
    items: 5,
  },
  smallDesktop: {
    breakpoint: { min: 1301, max: 1568 },
    items: 4,
  },
  tablet: {
    breakpoint: { min: 781, max: 1300 },
    items: 3,
  },
  bigMobile: {
    breakpoint: { min: 550, max: 780 },
    items: 2,
  },
  mobile: {
    breakpoint: { min: 0, max: 549 },
    items: 1,
  },
};

function LatestReviews({ reviews }: LatestReviewsProps): JSX.Element {
  const { t } = useTranslation('common');
  const { theme: themeMode } = useDarkMode();

  if (reviews.length === 0) return <></>;

  return (
    <CarouselWrapper>
      <Header>
        <div>
          <h2>{t('latestReviews.title')}</h2>
          <Image width={308} height={64} src={`/img/logos/logo-trustmate-2022-${themeMode}.svg`} alt="TrustMate Logo" />
        </div>
        <Description>{t('latestReviews.subTitle')}</Description>
      </Header>
      <Carousel
        customRightArrow={<ArrowBtnRightV2 />}
        customLeftArrow={<ArrowBtnLeftV2 />}
        responsive={responsive}
        containerClass="carousel-container"
        showDots={false}
        ssr
      >
        {reviews.map((review) => (
          <ReviewBox key={review.id} review={review} />
        ))}
      </Carousel>
    </CarouselWrapper>
  );
}

const CarouselWrapper = styled.div`
  background: ${({ theme }) => theme.THEME_MODE?.NAVBAR_BACKGROUND};
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  overflow: hidden;
  padding: 2.4rem;

  & > div {
    padding: 8rem 0;

    ${({ theme }) => theme.MEDIA.XL} {
      width: 112%;
    }
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  flex-direction: column;

  & > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3.2rem;

    ${({ theme }) => theme.MEDIA.XL} {
      align-items: baseline;
      flex-direction: row;
      gap: 1.2rem;
      margin-bottom: 2.4rem;
    }
  }

  h2 {
    font-size: 4rem;
    font-weight: 600;
    line-height: 5.5rem;
    letter-spacing: 0rem;
    text-align: center;
    color: ${({ theme }) => theme.THEME_MODE?.TEXT};
  }

  ${({ theme }) => theme.MEDIA.XL} {
    h2 {
      font-size: 5.2rem;
      font-weight: 700;
      line-height: 6rem;
    }
  }
`;

export const Description = styled.p`
  font-size: 1.4rem;
  margin: 0 0 2.4rem 0;
  text-align: center;
  font-weight: 400;
  color: ${({ theme }) => theme.COLORS.V2.GRAY400};

  @media ${({ theme }) => theme.DEVICE.MD} {
    font-size: 1.8rem;
    margin: 0 0 3.2rem 0;
  }

  @media ${({ theme }) => theme.DEVICE.LG} {
    font-size: 2.2rem;
    margin: 0 0 4rem 0;
    max-width: 80%;
  }

  @media ${({ theme }) => theme.DEVICE.XL} {
    font-size: 2.8rem;
    margin: 0 0 8rem 0;
  }
`;

const Wrapper = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.THEME_MODE?.FOOTER_CAROUSEL_BACKGROUND};
  border-radius: ${({ theme }) => theme.BORDER_RADIUS};
  color: ${({ theme }) => theme.THEME_MODE?.TEXT};
  box-shadow: ${({ theme }) => `0px 64px 128px -21.3333px ${theme.THEME_MODE?.FOOTER_CAROUSEL_SHADOW}`};
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto;
  justify-content: space-between;
  margin: 0 0.8rem;
  padding: 2.8rem 3.2rem;

  & > div:first-child {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const AuthorName = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.2rem;
  letter-spacing: 0em;
  text-align: left;
`;

const Time = styled.div`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.THEME_MODE?.TEXT};
`;

const Text = styled.p`
  color: ${({ theme }) => theme.COLORS.V2.GRAY400};
  height: 12rem;
  overflow-y: auto;
  font-size: 1.7rem;
  line-height: 3.2rem;
  letter-spacing: -0.01em;
  text-align: left;
  margin-bottom: 1.6rem;
  ${scrollBar};
`;

const CompanyName = styled.div`
  color: ${({ theme }) => theme.COLORS.SECONDARY};
  font-size: 1.8rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 2.2rem;
  letter-spacing: 0rem;
  text-align: left;

  a {
    color: inherit;
    font-weight: 600;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;

export default LatestReviews;
