import { ReactNode } from 'react';
import Link, { LinkProps } from 'next/link';

type NextLinkProps = {
  children: ReactNode;
} & LinkProps;

function NextLink({ children, ...linkProps }: NextLinkProps): JSX.Element {
  return (
    <Link {...linkProps} locale={false}>
      {children}
    </Link>
  );
}

export default NextLink;
