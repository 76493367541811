import styled from 'styled-components';

import ROUTES from 'config/routes';
import ImageLink from 'shared/components/ImageLink';
import NextLink from 'shared/components/NextLink';
import useTranslation from 'shared/hooks/useTranslation';

import ExpandableMenuItem, { ContainItemsProps } from './ExpandableMenuItem';
import LanguageSelector from './LanguageSelector';

type StyledMobileMenuProps = {
  open: boolean;
};

const StyledMobileMenu = styled.nav<StyledMobileMenuProps>`
  background: black;
  display: flex;
  flex-direction: column;
  font-size: 2.4rem;
  height: 100vh;
  justify-content: center;
  max-width: 35rem;
  overflow-y: scroll;
  padding: 2.8rem;
  position: fixed;
  right: 0;
  text-align: left;
  top: 0;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 9999;

  a {
    color: ${({ theme }) => theme.COLORS.ASH};
    cursor: pointer;
    font-weight: 400;
    letter-spacing: 0.1rem;
    padding: 1.2rem 0;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 57.6rem) {
      font-size: 1.8rem;
    }
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 8rem;
  padding: 0 0.8rem;
`;

const LanguageSelectorMobileWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  transform: translateX(-3.2rem);
  width: 120%;
`;

const NextItem = styled.div`
  color: ${({ theme }) => theme.COLORS.ASH};
  cursor: pointer;
  font-size: 2.4rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  line-height: 1.3;
  padding: 1.2rem 0;
  text-decoration: none;
  transition: color 0.3s linear;

  @media (max-width: 57.6rem) {
    font-size: 1.8rem;
  }
`;

const EuWrapper = styled.div`
  transform: translateY(0.4rem);
`;

type MobileMenuProps = {
  open: boolean;
  isLanguageSelector: boolean;
  solutionItems: ContainItemsProps[];
  whyUsItems: ContainItemsProps[];
};

function MobileMenu({ open, isLanguageSelector, solutionItems, whyUsItems }: MobileMenuProps): JSX.Element {
  const { t, lang } = useTranslation('common');

  return (
    <StyledMobileMenu open={open}>
      <LinksWrapper>
        <ExpandableMenuItem innerItems={whyUsItems}>
          <NextItem>{t('navbar.whyUs.name')}</NextItem>
        </ExpandableMenuItem>
        <ExpandableMenuItem innerItems={solutionItems}>
          <NextItem>{t('navbar.solutions.name')}</NextItem>
        </ExpandableMenuItem>
        <NextLink href={ROUTES.PRICING}>{t('navbar.pricing')}</NextLink>
        <NextLink href={ROUTES.LOG_IN}>{t('navbar.login')}</NextLink>
        <NextLink href={ROUTES.REGISTER}>{t('navbar.freeTrial')}</NextLink>
        <LanguageSelectorMobileWrapper>
          {isLanguageSelector ? <LanguageSelector colorInverted mobile /> : null}
          {lang === 'pl' ? (
            <EuWrapper>
              <ImageLink src="/img/shared/eu-logo-white.png" alt="eu logo" width={80} height={49} href={ROUTES.EU} />
            </EuWrapper>
          ) : null}
        </LanguageSelectorMobileWrapper>
      </LinksWrapper>
    </StyledMobileMenu>
  );
}

export default MobileMenu;
