import { formatDistance, Locale } from 'date-fns';
import { bg, cs, de, el, enUS, es, fr, hu, it, nl, lt, lv, pl, ptBR, ro, ru, sk, sv, uk, tr } from 'date-fns/locale';

const locales: { [key: string]: Locale } = {
  bg,
  cs,
  de,
  el,
  en: enUS,
  es,
  fr,
  hu,
  it,
  nl,
  lt,
  lv,
  pl,
  pt: ptBR,
  ro,
  ru,
  sk,
  sv,
  uk,
  tr,
};

// eslint-disable-next-line import/prefer-default-export
export function getTimeAgo(date: Date, language: string): string {
  return formatDistance(date, new Date(), {
    locale: locales[language] || locales.en,
    addSuffix: true,
  });
}
