import { useEffect } from 'react';
import useTranslation from 'shared/hooks/useTranslation';

function Widget(): JSX.Element | null {
  const { lang } = useTranslation();

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV === 'prod') {
      const srcUrl = `https://trustmate.io/api/widget/0b9dd60d-51a0-4c2c-8403-9452bfcde7f3/script?language=${lang}`;

      const s = document.createElement('script');
      const addScript = (src: string) => {
        s.setAttribute('src', src);
        s.setAttribute('async', 'async');
        document.body.append(s);
        s.remove();
      };
      addScript(srcUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // eslint-disable-next-line react/self-closing-comp
    <div id="0b9dd60d-51a0-4c2c-8403-9452bfcde7f3"></div>
  );
}

export default Widget;
