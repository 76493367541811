import { ReactNode } from 'react';
import { LinkProps } from 'next/link';
import NextLink from 'shared/components/NextLink';
import LinkButton from 'shared/components/LinkButton';

type NextLinkButtonProps = {
  children: ReactNode;
  nextLink?: boolean;
  className?: string;
} & LinkProps;

function NextLinkButton({ children, className, ...linkProps }: NextLinkButtonProps): JSX.Element {
  return (
    <NextLink {...linkProps} passHref>
      <LinkButton className={className}>{children}</LinkButton>
    </NextLink>
  );
}

export default NextLinkButton;
