import React, { ReactNode, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import WEB_ROUTES from 'config/routes';
import { DEVICE } from 'config/theme';
import Link from 'next/link';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import useTranslation from 'shared/hooks/useTranslation';

import NavLink from '../NavLink';
import { Icon } from './LanguageSelector';

type ColumnsProp = {
  isTwoColumns?: boolean;
};

type MediaProps = {
  isDesktop: boolean;
};

export type ContainItemsProps = {
  id: number;
  name: string;
  link: string;
  description: string;
  icon: string;
};

type ExpandableProps = {
  children: ReactNode;
  innerItems: ContainItemsProps[];
  withComparison?: boolean;
};

const ExpandableLink = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

const Wrapper = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.THEME_MODE?.BACKGROUND};
  box-shadow: 0px 33.3333px 34.6667px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: block;
  justify-content: center;
  left: 0;
  padding-bottom: 0.8rem;
  position: fixed;
  top: 7rem;
  width: 100vw;
`;

const SubMenuList = styled.ul<ColumnsProp>`
  column-gap: 6rem;
  display: grid;
  grid-template-columns: ${({ isTwoColumns }) => (isTwoColumns ? '1fr 1fr' : '1fr 1fr 1fr')};
  list-style-type: none;
  margin: 5rem 8rem 3rem 8rem;
  row-gap: 2rem;
  max-width: 160rem;

  @media (min-width: 1400px) {
    grid-template-columns: ${({ isTwoColumns }) => (isTwoColumns ? '1fr 1fr' : '1fr 1fr 1fr 1fr')};
  }
`;

const ListItem = styled.li<MediaProps>`
  align-items: flex-start;
  cursor: pointer;
  ${({ isDesktop }) =>
    isDesktop &&
    css`
      margin-bottom: 2.4rem;
    `}

  a {
    display: flex;
    justify-content: flex-start;
    color: ${({ theme }) => theme.THEME_MODE?.TEXT};
    text-decoration: none;
    ${({ isDesktop }) =>
      !isDesktop &&
      css`
        margin: 0 0 0 1.2rem;
        padding: 0;
      `}
  }
  :hover {
    opacity: 0.6;
  }
`;

const LinkHeader = styled.p`
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 2.4rem;
  margin-bottom: 1rem;
`;

const LinkDescription = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 120%;
`;

const MobileExpandedWrapper = styled.div`
  font-size: 1.8rem;
  padding: 1.2rem 0;
  position: relative;

  svg {
    position: absolute;
    right: -1.5rem;
    top: 3rem;
  }

  ul {
    margin-top: 1.6rem;
  }

  a {
    height: auto !important;
  }
`;

const ItemIcon = styled.img`
  filter: brightness(0) saturate(100%) invert(47%) sepia(95%) saturate(2203%) hue-rotate(6deg) brightness(105%)
    contrast(101%);
  height: auto;
  margin-right: 2rem;
  width: 3.2rem;
`;

const ComparisonNavLink = styled.a`
  color: inherit;
  display: flex;
  padding: 0.4em 0;
  position: relative;
  text-decoration: none;

  &:hover,
  &:focus {
    opacity: 0.6;

    &:before {
      transform: scaleX(1);
    }
  }
`;

const ComparisonSection = styled.div`
  display: grid;
  width: 20em;

  @media (min-width: 1400px) {
    grid-column: span 2;
  }

  ul {
    padding: 0.4em 0 0 1.6em;
  }
`;

function ExpandableMenuItem({ children, innerItems, withComparison = false }: ExpandableProps): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation('common');
  const expandedCardRef = useRef<HTMLDivElement>(null);
  const { isMatch: isDesktop, mediaLoaded } = useMediaQuery(DEVICE.XL);

  function handleOpen() {
    setExpanded(!expanded);
  }

  return mediaLoaded && isDesktop ? (
    <ExpandableLink
      // eslint-disable-next-line react/jsx-no-bind
      onMouseEnter={handleOpen}
      // eslint-disable-next-line react/jsx-no-bind
      onMouseLeave={handleOpen}
      ref={expandedCardRef}
    >
      {children}
      {innerItems && expanded ? (
        <Wrapper>
          <SubMenuList>
            {innerItems.map((item) => (
              <ListItem key={item.id} isDesktop>
                <Link href={item.link} passHref>
                  <ItemIcon src={`/img/shared/${item.icon}.svg`} />
                  <div>
                    <LinkHeader>{t(`navbar.${item.name}`)}</LinkHeader>
                    <LinkDescription>{t(`navbar.${item.description}`)}</LinkDescription>
                  </div>
                </Link>
              </ListItem>
            ))}
            {withComparison ? (
              <ComparisonSection>
                <LinkHeader>{t('navbar.whyUs.comparisonHeader')}</LinkHeader>
                <ul>
                  <li>
                    <ComparisonNavLink href={WEB_ROUTES.COMPARISON.OPINEO}>TrustMate vs Opineo</ComparisonNavLink>
                  </li>
                  <li>
                    <ComparisonNavLink href={WEB_ROUTES.COMPARISON.TRUSTEDSHOPS}>
                      TrustMate vs Trustedshops
                    </ComparisonNavLink>
                  </li>
                  <li>
                    <ComparisonNavLink href={WEB_ROUTES.COMPARISON.ZAUFANE}>TrustMate vs Zaufane.pl</ComparisonNavLink>
                  </li>
                </ul>
              </ComparisonSection>
            ) : null}
          </SubMenuList>
        </Wrapper>
      ) : null}
    </ExpandableLink>
  ) : (
    <MobileExpandedWrapper
      ref={expandedCardRef}
      // eslint-disable-next-line react/jsx-no-bind
      onClick={handleOpen}
    >
      {children}
      {innerItems && expanded ? (
        <ul>
          {innerItems.map((item) => (
            <ListItem key={item.id} isDesktop={false}>
              <NavLink href={item.link} passHref activeMenu bold>
                {t(`navbar.${item.name}`)}
              </NavLink>
            </ListItem>
          ))}
        </ul>
      ) : null}
      {innerItems ? <Icon dark={false} isRotated={expanded} /> : null}
    </MobileExpandedWrapper>
  );
}

export default ExpandableMenuItem;
