/* eslint-disable max-len */
import ROUTES from 'config/routes';

export const SOLUTIONS_ITEMS = [
  {
    name: 'solutions.collectReviews.name',
    link: ROUTES.COLLECTING_REVIEWS,
    description: 'solutions.collectReviews.description',
    icon: 'collecting-reviews-submenu',
  },
  {
    name: 'solutions.effigy.name',
    link: ROUTES.CLIENT_CONTACT,
    description: 'solutions.effigy.description',
    icon: 'effigy-submenu',
  },
  {
    name: 'solutions.increaseSale.name',
    link: ROUTES.INCREASE_SALES,
    description: 'solutions.increaseSale.description',
    icon: 'increase-sale-submenu',
  },
  {
    name: 'solutions.google.name',
    link: ROUTES.GOOGLE,
    description: 'solutions.google.description',
    icon: 'google-submenu',
  },
  {
    name: 'solutions.surveysPolls.name',
    link: ROUTES.SURVEYS_AND_POLLS,
    description: 'solutions.surveysPolls.description',
    icon: 'surveys-polls-submenu',
  },
  {
    name: 'whyUs.reviewTranslate.name',
    link: ROUTES.REVIEW_TRANSLATE,
    description: 'whyUs.reviewTranslate.description',
    icon: 'translate-submenu',
  },
  {
    name: 'solutions.badges.name',
    link: ROUTES.BADGES,
    description: 'solutions.badges.description',
    icon: 'badges',
  },
  {
    name: 'solutions.integrations.name',
    link: ROUTES.INTEGRATIONS,
    description: 'solutions.integrations.description',
    icon: 'integrations-submenu',
  },
];

export const SOLUTIONS_ITEMS_V2 = [
  {
    id: 1,
    name: 'solutions.collectReviews.name',
    link: ROUTES.COLLECTING_REVIEWS,
    description: 'solutions.collectReviews.description',
    icon: 'collecting-reviews',
  },
  {
    id: 2,
    name: 'solutions.increaseSale.name',
    link: ROUTES.INCREASE_SALES,
    description: 'solutions.increaseSale.description',
    icon: 'increase-sales',
  },
  {
    id: 3,
    name: 'solutions.traffic.name',
    link: ROUTES.TRAFFIC,
    description: 'solutions.traffic.description',
    icon: 'grid',
  },
  {
    id: 4,
    name: 'solutions.effigy.name',
    link: ROUTES.CLIENT_CONTACT,
    description: 'solutions.effigy.description',
    icon: 'star',
  },
  {
    id: 5,
    name: 'solutions.google.name',
    link: ROUTES.GOOGLE,
    description: 'solutions.google.description',
    icon: 'google',
  },
  {
    id: 6,
    name: 'whyUs.reviewTranslate.name',
    link: ROUTES.REVIEW_TRANSLATE,
    description: 'whyUs.reviewTranslate.description',
    icon: 'translate',
  },
  {
    id: 7,
    name: 'solutions.crossborderSales.name',
    link: ROUTES.CROSSBORDER_SALES,
    description: 'solutions.crossborderSales.description',
    icon: 'globe',
  },
  {
    id: 8,
    name: 'solutions.surveysPolls.name',
    link: ROUTES.SURVEYS_AND_POLLS,
    description: 'solutions.surveysPolls.description',
    icon: 'document',
  },
  {
    id: 9,
    name: 'solutions.productPolls.name',
    link: ROUTES.PRODUCT_POLLS,
    description: 'solutions.productPolls.description',
    icon: 'arrow-up',
  },
  {
    id: 10,
    name: 'solutions.badges.name',
    link: ROUTES.BADGES,
    description: 'solutions.badges.description',
    icon: 'chart',
  },
  {
    id: 11,
    name: 'solutions.moreSales.name',
    link: ROUTES.MORE_SALES,
    description: 'solutions.moreSales.description',
    icon: 'increase',
  },
  {
    id: 12,
    name: 'solutions.integrations.name',
    link: ROUTES.INTEGRATIONS,
    description: 'solutions.integrations.description',
    icon: 'integrations',
  },
];

export const WHY_US_ITEMS = [
  {
    id: 1,
    name: 'whyUs.whyUs.name',
    link: ROUTES.WHY_US,
    description: 'whyUs.whyUs.description',
    icon: 'why-us',
  },
  {
    id: 2,
    name: 'whyUs.references.name',
    link: ROUTES.REFERENCES,
    description: 'whyUs.references.description',
    icon: 'references',
  },
];
