/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import ROUTES from 'config/routes';
import { DEVICE } from 'config/theme';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import ImageLink from 'shared/components/ImageLink';
import NextLink from 'shared/components/NextLink';
import NextLinkButton from 'shared/components/NextLinkButton';
import { useDarkMode } from 'shared/hooks/useDarkMode';
import useLanguageCookie from 'shared/hooks/useLanguageCookie';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import useTranslation from 'shared/hooks/useTranslation';

import NavLink from '../NavLink';
import Burger from './Burger';
import ExpandableMenuItem from './ExpandableMenuItem';
import LanguageSelector from './LanguageSelector';
import MobileMenu from './MobileMenu';
import { SOLUTIONS_ITEMS_V2, WHY_US_ITEMS } from './SubMenuItems';

const GeoIp = dynamic(() => import('shared/components/GeoIp'), { ssr: false });

function NavBar(): JSX.Element {
  const { t, lang } = useTranslation('common');
  const { theme: themeMode } = useDarkMode();

  const { pathname, locale } = useRouter();
  const noMargin = pathname === ROUTES.PARTNERS || pathname.startsWith(`${ROUTES.REFERENCES}/`);

  const { isMatch: isDesktop, mediaLoaded } = useMediaQuery(DEVICE.XL);
  const languageCookie = useLanguageCookie();

  const [open, setOpen] = useState(false);
  const [isLanguageSelector, setLanguageSelector] = useState(true);

  useEffect(() => {
    if (pathname === ROUTES.COMPARISON.OPINEO || pathname === ROUTES.HOTELS) {
      setLanguageSelector(false);
    } else {
      setLanguageSelector(true);
    }
  }, [pathname]);

  return (
    <>
      <Header>
        {languageCookie ? null : <GeoIp />}
        <Nav>
          <NavContent>
            <NextLink href={ROUTES.HOME} passHref>
              <Logo>
                <img src={`/img/logos/logo-trustmate-2022-${themeMode}.svg`} width="308" height="64" alt="logo" />
              </Logo>
            </NextLink>
            <Menu>
              <div>
                <ExpandableMenuItem innerItems={WHY_US_ITEMS} withComparison={locale === 'pl'}>
                  <StyledNavLink href="#">{t('navbar.whyUs.name')}</StyledNavLink>
                </ExpandableMenuItem>
                <ExpandableMenuItem innerItems={SOLUTIONS_ITEMS_V2}>
                  <StyledNavLink href="#">{t('navbar.solutions.name')}</StyledNavLink>
                </ExpandableMenuItem>
                <StyledNavLink href={ROUTES.PRICING} passHref>
                  {t('navbar.pricing')}
                </StyledNavLink>
              </div>
              <div>
                <StyledNavLink
                  href={pathname.startsWith(`${ROUTES.PARTNERS}`) ? ROUTES.PARTNERS_LOGIN : ROUTES.LOG_IN}
                  passHref
                  orange
                  bold
                >
                  {t('navbar.login')}
                </StyledNavLink>
                <NavLinkButton
                  href={pathname.startsWith(`${ROUTES.PARTNERS}`) ? ROUTES.PARTNERS_REGISTER : ROUTES.REGISTER}
                >
                  {t('navbar.freeTrial')}
                </NavLinkButton>
                {isLanguageSelector ? <LanguageSelector /> : null}
                {lang === 'pl' ? (
                  <EuWrapper>
                    <ImageLink src="/img/shared/eu-logo.png" alt="eu logo" width={80} height={49} href={ROUTES.EU} />
                  </EuWrapper>
                ) : null}
              </div>
            </Menu>
            {mediaLoaded && !isDesktop ? (
              <MobileMenuWrapper>
                <Burger open={open} setOpen={setOpen} />
                <MobileMenu
                  solutionItems={SOLUTIONS_ITEMS_V2}
                  whyUsItems={WHY_US_ITEMS}
                  isLanguageSelector={isLanguageSelector}
                  open={open}
                />
              </MobileMenuWrapper>
            ) : null}
          </NavContent>
        </Nav>
      </Header>
      {noMargin ? null : <HeaderMargin isLarge={!languageCookie} />}
    </>
  );
}

const Header = styled.header`
  position: ${({ theme }) => theme.THEME_MODE?.NAVBAR_POSITION};
  top: 0;
  width: 100%;
  z-index: 1002;
  max-width: 100vw;
`;

type HeaderMarginProps = {
  isLarge: boolean;
};

const HeaderMargin = styled.div<HeaderMarginProps>`
  height: 8rem;
`;

const Nav = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.THEME_MODE?.NAVBAR_BACKGROUND};
  display: flex;
  justify-content: center;
`;

const NavContent = styled.nav`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  font-size: 1.8rem;
  height: 8rem;
  justify-content: space-between;
  max-width: 180rem;
  min-height: 6.4rem;
  padding: 0 3.2rem;
  width: 100%;

  ${({ theme }) => theme.MEDIA.SM} {
    padding: 0 3.2rem;
  }

  ${({ theme }) => theme.MEDIA.MD} {
    padding: 0 6.4rem;
  }

  ${({ theme }) => theme.MEDIA.LG} {
    padding: 0 2.4rem 0 8rem;
  }

  ${({ theme }) => theme.MEDIA.XL} {
    padding: 0 4rem;
  }

  ${({ theme }) => theme.MEDIA.XXL} {
    padding: 0 6rem;
  }
`;

const Logo = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  margin-right: 5rem;
  min-height: 6rem;

  img {
    height: auto;
    transform: translateY(-20%);
    width: 15rem;

    ${({ theme }) => theme.MEDIA.MD} {
      height: 3.4rem;
      width: auto;
    }

    @media (min-width: 1400px) {
      height: 4rem;
    }
  }
`;

const Menu = styled.div`
  align-items: center;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  & > div {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  ${({ theme }) => theme.MEDIA.XL} {
    display: flex;
  }
`;

const StyledNavLink = styled(NavLink)`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.1rem;
  margin: 0;
  padding: 0 2rem;

  @media (min-width: 1400px) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  @media (min-width: 1550px) {
    font-size: 2.1rem;
    line-height: 3.2rem;
  }

  ${({ theme }) => theme.MEDIA.XXL} {
    padding: 0 4rem;
  }

  &:hover {
    opacity: 0.6;
  }
`;

const NavLinkButton = styled(NextLinkButton)`
  font-size: 1.6rem;
  font-weight: 600;
  height: fit-content;
  line-height: 2.4rem;
  margin-left: 0.8rem;
  padding: 1.9rem 2rem;

  @media (min-width: 1400px) {
    font-size: 1.9rem;
    padding: 1.9rem 3rem;
  }

  @media (min-width: 1550px) {
    font-size: 2.1rem;
    padding: 1.9rem 4rem;
  }
`;

const MobileMenuWrapper = styled.div`
  display: flex;

  ${({ theme }) => theme.MEDIA.XL} {
    display: none;
  }
`;

const EuWrapper = styled.div`
  margin-left: 0.4em;
  transform: translateY(0.4rem);
`;

export default NavBar;
