import { useRef, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import styled, { css } from 'styled-components';

type ToolTipStyleProps = {
  tooltipPosition: string;
};

const ToolTipStyle = styled.div<ToolTipStyleProps>`
  position: ${({ tooltipPosition }) => tooltipPosition};
`;

const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
`;

type HintProps = {
  textalign: string;
  width: string;
  position: 'top' | 'bottom' | 'left' | 'right';
};

const Hint = styled(animated.div)<HintProps>`
  box-shadow: ${({ theme }) => theme.SHADOWS.PRIMARY};
  border-radius: ${({ theme }) => theme.BORDER_RADIUS};
  background-color: ${({ theme }) => theme.COLORS.SECONDARY_BLUE};
  color: white;
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.FONTS.PRIMARY};
  padding: 8px 16px;
  position: absolute;
  text-align: ${({ textalign }) => textalign || 'center'};
  width: ${({ width }) => width};
  z-index: 11;

  ${({ position }) =>
    position === 'top' &&
    css`
      bottom: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);

      ${Arrow} {
        bottom: -8px;
        left: calc(50% - 10px);
        border-right: 10px solid transparent;
        border-top: 10px solid ${({ theme }) => theme.COLORS.SECONDARY_BLUE};
        border-left: 10px solid transparent;
      }
    `}

  ${({ position }) =>
    position === 'bottom' &&
    css`
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);

      ${Arrow} {
        top: -8px;
        left: calc(50% - 10px);
        border-right: 10px solid transparent;
        border-bottom: 10px solid ${({ theme }) => theme.COLORS.SECONDARY_BLUE};
        border-left: 10px solid transparent;
      }
    `}

  ${({ position }) =>
    position === 'left' &&
    css`
      right: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);

      ${Arrow} {
        right: -8px;
        border-bottom: 10px solid transparent;
        border-top: 10px solid transparent;
        border-left: 10px solid ${({ theme }) => theme.COLORS.SECONDARY_BLUE};
        top: 50%;
        transform: translateY(-50%);
      }
    `}
  
  ${({ position }) =>
    position === 'right' &&
    css`
      left: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);

      ${Arrow} {
        left: -8px;
        border-bottom: 10px solid transparent;
        border-top: 10px solid transparent;
        border-right: 10px solid ${({ theme }) => theme.COLORS.SECONDARY_BLUE};
        top: 50%;
        transform: translateY(-50%);
      }
    `}
`;

type ToolTipProps = {
  title: string | React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  children: React.ReactNode;
  width?: string;
  textalign?: string;
  tooltipPosition?: string;
};

function Tooltop({
  title,
  position = 'top',
  children,
  width = 'auto',
  textalign = 'center',
  tooltipPosition = 'relative',
}: ToolTipProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const node = useRef<any | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const [{ opacity }, setOpacity] = useSpring(() => ({ opacity: 0 }));
  const [{ display }, setDisplay] = useSpring(() => ({ display: 'none' }));

  return (
    <ToolTipStyle
      tooltipPosition={tooltipPosition}
      ref={node}
      onMouseEnter={() => {
        setIsVisible(true);
        setOpacity({ opacity: 1 });
        setDisplay({ display: 'block' });
      }}
      onMouseLeave={() => {
        setIsVisible(false);
        setOpacity({ opacity: 0 });
        setDisplay({ display: 'none' });
      }}
    >
      {children}
      {isVisible && (
        <Hint
          position={position}
          style={{
            opacity: opacity.interpolate((o) => o),
            display: display.interpolate((d) => d),
          }}
          width={width}
          textalign={textalign}
        >
          <Arrow />
          {title}
        </Hint>
      )}
    </ToolTipStyle>
  );
}

export default Tooltop;
