import Image from 'next/image';
import NextLink from 'shared/components/NextLink';

type ImageLinkProps = {
  href: string;
  src: string;
  alt: string;
  width: number;
  height: number;
  className?: string;
};

function ImageLink({ href, src, alt, width, height, className }: ImageLinkProps): JSX.Element {
  return (
    <NextLink href={href}>
      <Image src={src} alt={alt} width={width} height={height} className={className} lazyBoundary="1000px" />
    </NextLink>
  );
}

export default ImageLink;
