import Script from 'next/script';

function Hubspot(): JSX.Element | null {
  return process.env.NEXT_PUBLIC_APP_ENV === 'prod' ? (
    <>
      <Script type="text/javascript" id="hs-script-loader" async defer src="//js-na1.hs-scripts.com/19988606.js" />
    </>
  ) : null;
}

export default Hubspot;
