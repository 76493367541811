import styled from 'styled-components';

import { buttonStyles, outlinedButtonStyles } from 'shared/components/mixins';

interface LinkButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
}

const LinkButton = styled.span<LinkButtonProps>`
  ${buttonStyles};
  text-decoration: none;
`;

export const LinkAButton = styled.a<LinkButtonProps>`
  ${buttonStyles};
  text-decoration: none;
`;

export const OutlinedLinkButton = styled.a`
  ${buttonStyles};
  ${outlinedButtonStyles};
  text-decoration: none;
`;

export default LinkButton;
