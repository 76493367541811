import { css } from 'styled-components';

export const secondaryBlueTransition = css`
  background: transparent;
  transition: background 0.3s;

  &:hover {
    background: ${({ theme }) => theme.COLORS.SECONDARY_BLUE_HOVER};
  }

  &:focus {
    background: ${({ theme }) => theme.COLORS.SECONDARY_BLUE_FOCUS};
    outline: 0;
  }
`;

export const scrollBar = css`
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    border-radius: ${({ theme }) => theme.BORDER_RADIUS};
    background-color: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.COLORS.V2.PRIMARY};
    outline: none;
    border-radius: ${({ theme }) => theme.BORDER_RADIUS};
  }
`;

export const buttonStyles = css`
  background: ${({ theme }) => theme.COLORS.SECONDARY};
  border: none;
  border-radius: ${({ theme }) => theme.BORDER_RADIUS};
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  padding: 16px 24px;
  text-align: center;
  transition: all 0.2s;

  ${({ theme }) => theme.MEDIA.SM} {
    font-size: 15px;
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.COLORS.SECONDARY_ORANGE};
    color: #ffffff;
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.COLORS.SECONDARY};
    }
  }

  &:focus {
    outline: none;
  }
`;

export const outlinedButtonStyles = css`
  border: 1px solid ${({ theme }) => theme.COLORS.SECONDARY};
  background-color: rgba(0, 0, 0, 0);
  color: ${({ theme }) => theme.COLORS.SECONDARY};

  &:disabled {
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.COLORS.SECONDARY_ASH};
    }
  }

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0);
  }

  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: ${({ theme }) => theme.COLORS.SECONDARY};
  }
`;
