import styled, { css } from 'styled-components';

type StyledBurgerProps = {
  open: boolean;
};

const StyledBurger = styled.button<StyledBurgerProps>`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.THEME_MODE?.TEXT};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 3.2rem;
  justify-content: space-around;
  padding: 0;
  width: 3.2rem;
  z-index: 999999;

  &:focus {
    outline: none;
  }

  ${({ theme }) => theme.MEDIA.XL} {
    display: none;
  }

  div {
    background-color: ${({ theme }) => theme.THEME_MODE?.TEXT};
    ${({ open }) =>
      open &&
      css`
        background-color: white;
      `}
    border-radius: 0.1rem;
    height: 0.4rem;
    position: relative;
    transition: all 0.3s linear;
    transform-origin: 1px;
    width: 3.2rem;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(0.2rem)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

type BurgerProps = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

function Burger({ open, setOpen }: BurgerProps): JSX.Element {
  return (
    <StyledBurger
      open={open}
      onClick={() => setOpen(!open)}
      aria-label="Main menu"
      role="button"
      aria-labelledby="menu-label"
    >
      <div />
      <div />
      <div />
    </StyledBurger>
  );
}

export default Burger;
