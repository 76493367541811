import styled, { css } from 'styled-components';

import Link from 'next/link';

export type NavLinkProps = {
  bold?: boolean;
  activeMenu?: boolean;
  orange?: boolean;
  expanded?: boolean;
  darkMode?: boolean;
};

const NavLink = styled(Link)<NavLinkProps>`
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: ${({ bold }) => (bold ? '600 !important' : '400')};
  font-size: inherit;
  line-height: 1.2em;
  padding: 0;
  position: relative;
  margin: 4px 10px 4px 24px;
  text-align: left;
  color: ${({ theme, activeMenu }) => (activeMenu ? theme.THEME_MODE?.TEXT : theme.THEME_MODE?.TEXT)};
  ${({ orange }) =>
    orange &&
    css`
      color: ${({ theme }) => theme.COLORS.SECONDARY};
    `}
  &:focus {
    outline: none;
  }

  ${({ expanded }) =>
    !expanded &&
    css`
      height: 6.4rem;
      display: flex;
      align-items: center;
    `}
`;

export default NavLink;
